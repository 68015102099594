<script>
import { transactionsService } from "../../../helpers/backend/transactions.service"
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      filter_type: "",
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "id" },
        { key: "order_id", sortable: true, label: "Transaction id" },
        { key: "midtrans_id", sortable: true, label: "Midtrans id" },
        { key: "serial_number", sortable: true, label: "Machine" },
        { key: "principle_name", sortable: true, label: "Principle Name" },
        { key: "location", sortable: true, label: "Location" },
        { key: "product_name", sortable: true, label: "Product Name" },
        { key: "gross_amount", sortable: true, label: "Gross Amount" },
        { key: "payment_method", sortable: true, label: "Payment Method" },
        { key: "created_at", sortable: true, label: "Transaction Date" },
        { key: "created_at_time", sortable: true, label: "Transaction Time" },
        { key: "status", sortable: true, label: "Status" },
        { key: "action", sortable: true, label: "Action" },
      ],
      showDismissibleAlert: false,
      selectedObject: null,
      selectedObjectArray: [],
    }
  },
  filters: {
    titleize(str) {
      return str.split('_').map(function (item) {
        return item.charAt(0).toUpperCase() + item.substring(1)
      }).join(' ')
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    },
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    setActive(b) {
      this.active = b
    },
    refreshList() {
      transactionsService.getAll().then(
        (data) => {
          this.data = data.transactions
          this.loading = false
        },
        (err) => {
          this.error =
            "Failed to fetch transactions type data. Error : " +
            err +
            "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      this.refreshList()
    },
    // onDelete(id) {
    //   if(confirm("Do you really want to delete this instance, this action can't be undone?")){
    //     advertisementService.deleteAdvertismenetTypeById(id).then(
    //       data => {
    //         this.fetchData()
    //         console.log(data)
    //       },
    //       err => {
    //         this.error = "Failed to delete advertisement. Error : " + err;
    //         this.showDismissibleAlert=true
    //       }
    //     )
    //   }
    // },
    // onUpdate(id) {
    //   this.$emit('onEdit',id);
    // }
    onDetailView(object) {
      this.selectedObject = object
      this.selectedObjectArray = []
      var keys = Object.keys(this.selectedObject)
      for (let index = 0;index < keys.length;index++) {
        const element = keys[index]
        this.selectedObjectArray.push({
          key: element,
          value: this.selectedObject[element],
        })
      }
      this.$refs["modal-detail"].show()
    },
    onRefreshStatus(object) {
      if(object.id > 0){
        transactionsService.refreshStatus(object.id).then(
          data => {
            object.status = data.transaction.status
          }, err => {
            this.error = "Failed to refresh. Error: " + err;
            this.showDismissibleAlert = true;
          }
        )
      }else{
        this.error = "Failed to refresh. Error.";
        this.showDismissibleAlert = true;
      }
    },
  },
};
</script>

<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <b-modal
      size="lg"
      ok-only
      id="modal-detail"
      ref="modal-detail"
      title="Transaction Details"
    >
      <div v-if="this.selectedObject">
        <div class="row" v-for="field in selectedObjectArray" :key="field.key">
          <div class="col-sm-3">{{ field.key | titleize }}</div>
          :
          <div class="col-sm-8">{{ field.value }}</div>
        </div>
      </div>
      <!-- <p class="my-4" v-for="i in 20" :key="i">
      Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
      in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
    </p> -->
    </b-modal>
    <div class="card">
      <div class="card-body">
        <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

        <!-- <h4 class="card-title mb-4">(.*)</h4> -->
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-4">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="data"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(status)="row">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-success': row.item.status == 'settlement',
                  'badge-warning': row.item.status == 'pending',
                  'badge-danger': row.item.status == 'cancel',
                }"
              >
                {{ row.item.status }}
              </div>
            </template>

            <template v-slot:cell(serial_number)="row">
              <div class="badge font-size-12 badge-success">
                {{ row.value }}
              </div> </template
            ><template v-slot:cell(gross_amount)="row">
              {{
                "Rp " +
                row.value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
              }}
            </template>
            <template v-slot:cell(created_at)="row">
              {{ new Date(row.value).toDateString() }}
            </template>
            <template v-slot:cell(created_at_time)="item">
              {{ new Date(item.item.created_at).toLocaleTimeString() }}
            </template>
            <template v-slot:cell(action)="row">
              <a
              @click="onDetailView(row.item)"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="View Planogram"
              >
                <i class="mdi mdi-eye font-size-18"></i>
              </a>
              <a 
              @click="onRefreshStatus(row.item)"
              class="text-success" 
              v-b-tooltip.hover 
              title="Refresh List"
              >
                <i class="mdi mdi-refresh font-size-18"></i>
              </a>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>