import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const transactionsService = {
    getAll,
    refreshStatus,
};

function getAll(filter) {
    const requestOptions = {
        url : '/api/v1/user/transactions',
        method: 'GET',
        params: filter,
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function refreshStatus(transactionID) {
    const requestOptions = {
        url : '/api/v1/user/transaction/refresh_status/' + transactionID,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}